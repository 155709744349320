<template>
  <van-tabs
    v-model="active"
    color="#8DC6D6"
    line-height="2px"
    title-active-color="#8DC6D6"
    line-width="100px"
  >
    <van-tab title="全部">
      <div class="container">
        <div
          class="perSon_information"
          v-for="item in orderInformation"
          :key="item.id"
        >
          <div class="information_top">
            <div class="museumName">{{ item.museumName }}</div>
            <div class="museumStates" v-if="item.states == 1">待使用</div>
            <div class="museumStates" v-if="item.states == 2">已使用</div>
            <div class="museumStates" v-if="item.states == 3">已取消</div>
            <div class="museumStates" v-if="item.states == 4">已过期</div>
          </div>
          <div class="information_center">
            <div class="Time">
              <span class="text">预约时间：</span
              ><span>{{ item.reservationDate }} {{ item.arrivalTime }}</span>
            </div>
            <div class="personNumber">
              <span class="text">参观人数：</span><span>{{ item.num }}</span>
            </div>
            <div class="visitor">
              <span class="text text2">参观人<span class="text3">:</span></span
              ><span>{{ item.name }}</span>
            </div>
          </div>
          <div class="information_bottom">
            <div
              class="quxiao"
              v-if="item.states !== 1"
              @click="deleteDingdan(item)"
            >
              删除
            </div>
            <div
              class="quxiao"
              v-if="item.states == 1"
              @click="cancelDingdan(item)"
            >
              取消
            </div>
            <div class="dianzipingzheng" @click="pushElectronic(item)">
              电子凭证
            </div>
          </div>
        </div>

        <div class="no" v-if="orderInformation.length == 0">
          <img :src="localPic + '15.png'" />
          <p>暂无订单记录</p>
        </div>
      </div>
    </van-tab>
    <van-tab title="待使用">
      <div class="container">
        <div v-for="item in orderInformation1" :key="item.id">
          <div class="perSon_information" v-if="item.states == 1">
            <div class="information_top">
              <div class="museumName">{{ item.museumName }}</div>
              <div class="museumStates" v-if="item.states == 1">待使用</div>
              <div class="museumStates" v-if="item.states == 2">已使用</div>
              <div class="museumStates" v-if="item.states == 3">已取消</div>
              <div class="museumStates" v-if="item.states == 4">已过期</div>
            </div>
            <div class="information_center">
              <div class="Time">
                <span class="text">预约时间：</span
                ><span>{{ item.reservationDate }} {{ item.arrivalTime }}</span>
              </div>
              <div class="personNumber">
                <span class="text">参观人数：</span><span>{{ item.num }}</span>
              </div>
              <div class="visitor">
                <span class="text text2"
                  >参观人<span class="text3">:</span></span
                ><span>{{ item.name }}</span>
              </div>
            </div>
            <div class="information_bottom">
              <div
                class="quxiao"
                v-if="item.states !== 1"
                @click="deleteDingdan(item)"
              >
                删除
              </div>
              <div
                class="quxiao"
                v-if="item.states == 1"
                @click="cancelDingdan(item)"
              >
                取消
              </div>
              <div class="dianzipingzheng" @click="pushElectronic(item)">
                电子凭证
              </div>
            </div>
          </div>
        </div>
        <div class="no" v-if="orderInformation1.length == 0">
          <img :src="localPic + '15.png'" />
          <p>暂无待使用记录</p>
        </div>
      </div>
    </van-tab>
    <van-tab title="已使用">
      <div class="container">
        <div v-for="item in orderInformation2" :key="item.id">
          <div class="perSon_information" v-if="item.states == 2">
            <div class="information_top">
              <div class="museumName">{{ item.museumName }}</div>
              <div class="museumStates" v-if="item.states == 1">待使用</div>
              <div class="museumStates" v-if="item.states == 2">已使用</div>
              <div class="museumStates" v-if="item.states == 3">已取消</div>
              <div class="museumStates" v-if="item.states == 4">已过期</div>
            </div>
            <div class="information_center">
              <div class="Time">
                <span class="text">预约时间：</span
                ><span>{{ item.reservationDate }} {{ item.arrivalTime }}</span>
              </div>
              <div class="personNumber">
                <span class="text">参观人数：</span><span>{{ item.num }}</span>
              </div>
              <div class="visitor">
                <span class="text text2"
                  >参观人<span class="text3">:</span></span
                ><span>{{ item.name }}</span>
              </div>
            </div>
            <div class="information_bottom">
              <div
                class="quxiao"
                v-if="item.states !== 1"
                @click="deleteDingdan(item)"
              >
                删除
              </div>
              <div
                class="quxiao"
                v-if="item.states == 1"
                @click="cancelDingdan(item)"
              >
                取消
              </div>
              <div class="dianzipingzheng" @click="pushElectronic(item)">
                电子凭证
              </div>
            </div>
          </div>
        </div>
        <div class="no" v-if="orderInformation2.length == 0">
          <img :src="localPic + '15.png'" />
          <p>暂无已使用记录</p>
        </div>
      </div>
    </van-tab>
    <van-tab title="已取消">
      <div class="container">
        <div v-for="item in orderInformation3" :key="item.id">
          <div class="perSon_information" v-if="item.states == 3">
            <div class="information_top">
              <div class="museumName">{{ item.museumName }}</div>
              <div class="museumStates" v-if="item.states == 1">待使用</div>
              <div class="museumStates" v-if="item.states == 2">已使用</div>
              <div class="museumStates" v-if="item.states == 3">已取消</div>
              <div class="museumStates" v-if="item.states == 4">已过期</div>
            </div>
            <div class="information_center">
              <div class="Time">
                <span class="text">预约时间：</span
                ><span>{{ item.reservationDate }} {{ item.arrivalTime }}</span>
              </div>
              <div class="personNumber">
                <span class="text">参观人数：</span><span>{{ item.num }}</span>
              </div>
              <div class="visitor">
                <span class="text text2"
                  >参观人<span class="text3">:</span></span
                ><span>{{ item.name }}</span>
              </div>
            </div>
            <div class="information_bottom">
              <div
                class="quxiao"
                v-if="item.states !== 1"
                @click="deleteDingdan(item)"
              >
                删除
              </div>
              <div
                class="quxiao"
                v-if="item.states == 1"
                @click="cancelDingdan(item)"
              >
                取消
              </div>
              <div class="dianzipingzheng" @click="pushElectronic(item)">
                电子凭证
              </div>
            </div>
          </div>
        </div>
        <div class="no" v-if="orderInformation3.length == 0">
          <img :src="localPic + '15.png'" />
          <p>暂无已取消记录</p>
        </div>
      </div>
    </van-tab>
    <van-tab title="已过期">
      <div class="container">
        <div v-for="item in orderInformation4" :key="item.id">
          <div class="perSon_information" v-if="item.states == 4">
            <div class="information_top">
              <div class="museumName">{{ item.museumName }}</div>
              <div class="museumStates" v-if="item.states == 1">待使用</div>
              <div class="museumStates" v-if="item.states == 2">已使用</div>
              <div class="museumStates" v-if="item.states == 3">已取消</div>
              <div class="museumStates" v-if="item.states == 4">已过期</div>
            </div>
            <div class="information_center">
              <div class="Time">
                <span class="text">预约时间：</span
                ><span>{{ item.reservationDate }} {{ item.arrivalTime }}</span>
              </div>
              <div class="personNumber">
                <span class="text">参观人数：</span><span>{{ item.num }}</span>
              </div>
              <div class="visitor">
                <span class="text text2"
                  >参观人<span class="text3">:</span></span
                ><span>{{ item.name }}</span>
              </div>
            </div>
            <div class="information_bottom">
              <div
                class="quxiao"
                v-if="item.states !== 1"
                @click="deleteDingdan(item)"
              >
                删除
              </div>
              <div
                class="quxiao"
                v-if="item.states == 1"
                @click="cancelDingdan(item)"
              >
                取消
              </div>
              <div class="dianzipingzheng" @click="pushElectronic(item)">
                电子凭证
              </div>
            </div>
          </div>
        </div>
        <div class="no" v-if="orderInformation4.length == 0">
          <img :src="localPic + '15.png'" />
          <p>暂无已过期记录</p>
        </div>
      </div>
    </van-tab>
  </van-tabs>
</template>

<script>
import {
  myOrder,
  deletOrder,
  cancelOrder,
  localPic,
  LoginOuter,
} from "../api/api";
export default {
  data() {
    return {
      active: 0,
      signature: "",
      orderInformation: "", //全部订单信息
      localPic,
      orderInformation1: [], //待使用订单信息
      orderInformation2: [], //已使用订单信息
      orderInformation3: [], //已取消
      orderInformation4: [],
    };
  },

  created() {
    if (this.$route.query.userId) {
      console.log(this.$route.query.userId.replace(/\"/g, ""));
      this.userId = this.$route.query.userId.replace(/\"/g, "");
      localStorage.setItem("userId", this.userId);
      this.login();
    } else {
      console.log(1);
      this.getInformation();
    }

    this.signature = localStorage.getItem("signature");
    console.log(this.signature);
  },

  methods: {
    login() {
      var i = {
        appId: "ZSKJ_js8rwachLPIF5PtsFnl",
        openid: this.userId,
      };
      LoginOuter(i).then((res) => {
        console.log(res);
        this.getInformation();
      });
    },
    // 获取订单信息
    getInformation() {
      myOrder({ states: 0, hasZLB: true }).then((res) => {
        this.orderInformation = [...res.data.data];
        for (let i = 1; i < 5; i++) {
          this[`orderInformation${i}`] = [];
        }
        for (let i = 0; i < res.data.data.length; i++) {
          this[`orderInformation${res.data.data[i].states}`].push(
            res.data.data[i]
          );
          res.data.data.splice(i, 1);
        }
        console.log(this.orderInformation);
      });
    },
    // 跳转电子凭证
    pushElectronic(item) {
      console.log(item);
      this.$router.push({ path: "/Electronic", query: { information: item } });
      localStorage.setItem("electronicId", item.id);
    },
    //删除预约订单
    deleteDingdan(item) {
      console.log(item);
      deletOrder({ id: item.id }).then((res) => {
        console.log(res.data.data);
        if (res.data.data) {
          this.getInformation();
        }
      });
    },
    //取消预约订单
    cancelDingdan(item) {
      cancelOrder({ id: item.id }).then((res) => {
        console.log(res.data.data);
        if (res.data.data) {
          this.getInformation();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  overflow: scroll;
  padding: 0 30px;
  box-sizing: border-box;
  .no {
    width: 100%;
    img {
      width: 100%;
      height: 100%;

      margin-top: 128.76px;
    }

    p {
      font-size: 24px;
      font-weight: 400;
      line-height: 38px;
      color: rgba(113, 115, 118, 1);
      width: 100%;
      text-align: center;
      display: block;
    }
  }
  .perSon_information {
    width: 100%;

    margin-top: 40px;
    border-radius: 20px;
    padding: 20px;
    background-color: white;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    .information_top {
      display: flex;
      justify-content: space-between;
      justify-items: center;
      align-items: center;
      .museumName {
        font-weight: bold;
      }
      .museumStates {
        font-size: 26px;
        color: tomato;
      }
    }

    .information_center {
      font-size: 24px;
      width: 630px;
      color: #808084;
      .text {
        display: inline-block;
        text-align: right;
      }
      .text2 {
        text-align: left;
      }
      .text3 {
        text-align: right;
      }
    }
    .information_bottom {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
      .quxiao {
        font-size: 26px;
        padding: 10px 40px;
        border-radius: 40px;
        border: 1px solid #dedede;
      }
      .dianzipingzheng {
        font-size: 26px;
        padding: 10px 40px;
        border-radius: 40px;
        background-color: #8dc6d6;
        color: white;
        margin-left: 30px;
      }
    }
  }
}
</style>
